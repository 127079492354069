import { useCallback, useEffect, useState } from 'react';
import { cloneDeep, negate } from 'lodash';
import { usePathname, useRouter } from 'next/navigation';

// utils
import { useNavigation } from 'utils/hooks/use-navigation';
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';
import { shiftIndex } from '../utils/shift-index';
import { useNavOpen } from '../mega-menu-provider';
import {
  MAX_ELEMENTS_TO_SHOW,
  THIRD_LEVEL_WITH_PICTOGRAM_MAX_ELEMENTS_TO_SHOW,
} from '../mainnav/constants';
import { useIsPreview } from 'utils/hooks/useIsPreview';

export type PathNode = Partial<NavigationItem> & {
  selectedIndex?: number;
  active?: boolean;
  depth: number;
  withPictogram?: boolean;
};

export type TreePath = PathNode[];

const withIcon = (e: NavigationItem) => Boolean(e.image);
const withoutIcon = negate(withIcon);

export const useNavigationItems = () => {
  const navigation = useNavigation();
  const { isNavOpen } = useNavOpen();
  const pathname = usePathname();
  const router = useRouter();

  const isPreview = useIsPreview();
  const [treePath, setTreePath] = useState<TreePath>([
    { children: navigation, selectedIndex: -1, url: '/', depth: 0 },
  ]);

  const isDesktop = useIsDesktop({ gdds: true });
  const isMobile = useIsMobile();

  const findPath = useCallback(() => {
    const treePath = findTreePath(navigation, pathname)(navigation, [
      { children: navigation, url: '/', depth: 0 },
    ]);
    if (treePath) {
      setTreePath(treePath);
    }
  }, [navigation, pathname]);

  useEffect(() => {
    if (!isNavOpen) {
      // when closed: reset tree path to correct navigation level and current item
      findPath();
    }
  }, [isNavOpen, findPath]);

  useEffect(() => {
    findPath();
  }, [findPath]);

  const currentNavigationItem = treePath?.slice(0).pop();

  const handleOnBack = useCallback(() => {
    if (!isDesktop) {
      // Fixes: https://geberit.visualstudio.com/Web%20Platform/_workitems/edit/391143
      if (treePath.length > 1) {
        setTreePath((prevPath) => prevPath.slice(0, -1)); // Navigate up one level in the navigation tree on non-desktop
      }
    } else {
      const sliceStart = currentNavigationItem?.active ? 1 : 2;

      const url = treePath.slice(-sliceStart)?.shift()?.url;

      if (url && !isMobile) {
        router.push(url);
      }

      setTreePath((state) => {
        const clone = state.slice(0);
        const lastNode = clone.pop();
        clone[clone.length - 1].active = true;
        if (currentNavigationItem?.active && lastNode) {
          lastNode.active = false;
          clone.push(lastNode);
        }
        return clone;
      });
    }
  }, [currentNavigationItem?.active, treePath, router, isDesktop, isMobile]);

  // When we click an element, we want to customize the
  // navigation path so that the user sees direct
  // feedback before the find path hook runs,
  // evaluates the url once, and writes the
  // navigation path to state.
  // We can completely dispense with this step,
  // the navigation will still work properly,
  // only the user will wait a bit long to see visual feedback when clicking.
  const pushPathNode = useCallback(
    (nextNode: PathNode, index: number) => {
      setTreePath((state) => {
        const clone = state.slice(0);
        const currentLevel = nextNode.depth - 1;
        const nextLevel = nextNode.depth;
        const nextButOneLevel = nextNode.depth + 1;

        // We remove the next but one levels, if they exist
        if (clone[nextButOneLevel + 1]) {
          clone.pop();
        }
        if (clone[nextButOneLevel] && nextNode.label !== clone[nextLevel]?.label) {
          clone.pop();
        }

        // We set the navigation elements for the next level
        if (!clone[nextNode.depth] || clone[nextNode.depth].label !== nextNode.label) {
          clone[nextLevel] = nextNode;
        }

        // and set the current level as active.
        if (isDesktop && clone[currentLevel]) {
          clone[currentLevel].selectedIndex = index;
          clone[currentLevel].active = true;
        }

        return clone;
      });
    },
    [isDesktop],
  );

  const handlePathNode = useCallback(
    (pathNode?: PathNode) => {
      if (!pathNode) return undefined;

      pathNode.children = setOriginalIndex(pathNode.children);

      const clone = cloneDeep(pathNode?.children);

      if (pathNode.depth === 1) {
        const withIconChildren = clone?.filter(withIcon).slice(0, MAX_ELEMENTS_TO_SHOW) ?? [];
        const withoutIconChildren =
          clone
            ?.filter(withoutIcon)
            .slice(0, MAX_ELEMENTS_TO_SHOW - (withIconChildren?.length ?? 0)) ?? [];

        const lastElement = withIconChildren.at(withIconChildren.length - 1);
        if (lastElement) {
          // serves as a marker, so that we have a little distance
          // between the navigation with pictograms and without pictograms
          lastElement.isLast = true;
        }

        return {
          ...pathNode,
          children: [...withIconChildren, ...withoutIconChildren],
        };
      }

      if (pathNode.depth === 2) {
        const withPictogram = clone?.some((item) => item.image);
        const maxElementsToShow = withPictogram
          ? THIRD_LEVEL_WITH_PICTOGRAM_MAX_ELEMENTS_TO_SHOW
          : MAX_ELEMENTS_TO_SHOW;
        const placeholder = isPreview ? '/images/navigation/no-picto.svg' : undefined;
        return {
          ...pathNode,
          withPictogram,
          children: clone?.slice(0, maxElementsToShow).map((element) => ({
            ...element,
            image: withPictogram ? element.image ?? placeholder : undefined,
          })),
        };
      }

      return pathNode;
    },
    [isPreview],
  );

  return {
    navigation: handlePathNode(isDesktop ? treePath[0] : currentNavigationItem),
    secondLevelNavigation: handlePathNode(treePath[1]),
    thirdLevelNavigation: handlePathNode(treePath[2]),
    pushPathNode,
    handleOnBack,
    findPath,
  };
};

function findTreePath(navigation: NavigationItem[], pathname: string) {
  let result: TreePath = [{ children: navigation, url: '/', depth: 0 }];

  return function iterate(param: NavigationItem[], path: TreePath, depth = 1) {
    for (let index = 0; index < param?.length; index++) {
      const element = param[index];
      if (element.url === pathname) {
        shiftIndex(path, index);
        if (element.children) {
          result = [...path, { ...element, depth: depth }];
        } else {
          path[path.length - 1].active = true;
          result = path;
        }
        break;
      } else if (element.children) {
        iterate(
          element.children,
          [...path, { ...element, selectedIndex: index, depth: depth }],
          depth + 1,
        );
      }
    }
    return result;
  };
}

function setOriginalIndex(items?: NavigationItem[]): NavigationItem[] | undefined {
  return items?.map((item, index) => ({
    ...item,
    originalIndex: index,
  }));
}
