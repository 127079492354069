'use client';

import { useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

// utils
import createStore from '../store';
import { SizeProvider } from '../components/App/SizeProvider';
import { THEMES } from '../themes';
import { PreviewListener } from 'components/ContentCreator/PreviewListener';
import { SessionProvider } from '../components/Session/SessionContext';
import { AppInsights } from '../components/app-insights/app-insights';
import { mapTranslations } from './map-translations';

const queryClient = new QueryClient();

type RootLayoutContextProps = React.PropsWithChildren<{
  storeData: {
    translations: Record<string, { value: string }>;
    homepages: any[];
    globalProjectData: any;
    technicalLanguage: string;
    theme: string;
    server: Record<string, any>;
    mainNav: any;
    metaNav: any;
  };
  headersList: Record<string, string>;
}>;

export function Providers({ children, storeData, headersList }: Readonly<RootLayoutContextProps>) {
  const fallbackWidth = getFallbackWidth(headersList, storeData.theme);

  const store = useMemo(() => {
    const { store: reduxStore } = createStore('', {
      homepageList: { currentLanguage: storeData.technicalLanguage, item: storeData.homepages },
      navigation: {
        [storeData.technicalLanguage]: {
          main: { items: storeData.mainNav },
          meta: storeData.metaNav,
        },
      },
      translations: mapTranslations({ [storeData.technicalLanguage]: storeData.translations }),
      globals: {
        ...storeData.globalProjectData,
        theme: storeData.theme,
        server: storeData.server,
      },
    });
    return reduxStore;
  }, [storeData]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window._logLevel = storeData.server.logLevel;
    }
  }, [storeData.server.logLevel]);

  return (
    <SizeProvider fallbackWidth={fallbackWidth}>
      <ThemeProvider key="geberit/theme-provider" theme={THEMES[storeData.theme]}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PreviewListener>
              <SessionProvider>
                <AppInsights>{children}</AppInsights>
              </SessionProvider>
            </PreviewListener>
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </SizeProvider>
  );
}

function getFallbackWidth(headersList: Record<string, string>, theme: string) {
  const deviceType = headersList['x-device-type'];
  const isMobile = deviceType === 'mobile';
  const isTablet = deviceType === 'tablet';

  let fallbackWidth = 1024;
  if (isTablet) fallbackWidth = 768;
  if (isMobile) fallbackWidth = 360;
  return fallbackWidth;
}
