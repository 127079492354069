import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

// utils
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';

export function useElementHeight(ref: React.RefObject<HTMLElement | null>) {
  const [height, setHeight] = useState<number>(0);

  useIsomorphicLayoutEffect(() => {
    const newHeight = Math.round(ref?.current?.getBoundingClientRect().height || 0);
    setHeight(newHeight);
  }, [ref]);

  useResizeObserver(ref, (entry) => {
    setHeight(entry.contentRect.height);
  });
  return height;
}
