import { useContext, useRef, useMemo } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useSelector } from 'react-redux';
import { MainNavigationDesktop, Mobilenavigation } from '@geberit/gdds';

// styles
import {
  GlobalMainNavigationStyles,
  MainNavigationWrapper,
} from './gdds-main-navigation-wrapper.styles';

// components
import SearchOverlay from '../Search/Overlay';

// utils
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { navigationMainItemsSelector } from 'utils/selectors/navigationSelectors';
import { NavigationContext, NAVIGATION_OPEN, NAVIGATION_SEARCH_OPEN } from '../NavigationContext';
import { useTracking } from 'utils/hooks/useTracking';
import { navigationTrackingAction, searchTrackingAction } from '../trackingActions';
import {
  groupWorldwideSelector,
  logoSelector,
  locatorConfigurationSelector,
  globalsSearchDisabled,
} from 'utils/selectors/globalsSelectors';
import { buildSize, gridSizes, themeToGridSize } from 'utils/gridSize';
import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';
import { useMetaNaviData } from 'utils/hooks/use-meta-navi-data';
import { transformNavItemsRawFactory } from './transformers';
import { useThemeName } from 'utils/hooks/use-theme';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useHeaderPin } from 'utils/hooks/use-header-pin';

type GddsMainNavigationProps = {
  isDesktop?: boolean;
  isGddsDesktop?: boolean;
};

export default function GddsMainNavigation({
  isDesktop,
  isGddsDesktop,
}: Readonly<GddsMainNavigationProps>) {
  const translate = useTranslationFunction();
  const customLogo = useSelector(logoSelector);
  const homepage = useSelector(homepageSelector);
  const worldwideUrl = useSelector(groupWorldwideSelector);
  const navItemsRaw = useSelector(navigationMainItemsSelector);
  const locator = useSelector(locatorConfigurationSelector);
  const router = useRouter();
  const searchInputRef = useRef(undefined);
  const track = useTracking();
  const theme = useThemeName();
  const isNord = useNord();
  const pathname = usePathname();
  const isGroup = useGdds() && !isNord;
  const searchDisabled = useSelector(globalsSearchDisabled);
  const metanaviData = useMetaNaviData(isDesktop, isGddsDesktop);
  const headerPinEnabled = useHeaderPin().enabled;

  const homeLabel = translate('navigation_home');
  const backLabel = translate('navigation_back');

  const worldwideClick = () => {
    router.push(worldwideUrl);
  };

  const worldwide = worldwideUrl && {
    symbol: 'Globe',
    onClick: worldwideClick,
  };

  const navClick = (item: any) => (_, hasSubItems: boolean) => {
    track.trackEvent(navigationTrackingAction(item.label));

    if (!item.external) {
      if (item.window) {
        window.open(item.url);
      } else if (!hasSubItems) {
        router.push(item.url);
      }
    } else if (!item.window) {
      router.push(item.url);
    } else {
      window.open(item.url, '_blank');
    }
  };

  const { state, dispatch } = useContext(NavigationContext) || {};
  const toggleSearch = () => {
    if (!state.searchOpen && state.flyoutState === NAVIGATION_OPEN) {
      document.body.classList.remove('mobilenav-open');
    }

    dispatch({ type: NAVIGATION_SEARCH_OPEN, open: state && !state.searchOpen });
    if (searchInputRef.current && !state.searchOpen) {
      searchInputRef.current.focus();
    }

    track.trackEvent(searchTrackingAction('Search'));
  };

  const navItems = useMemo(() => {
    if (navItemsRaw) {
      const restructuredItems = navItemsRaw
        .filter((topItem) => topItem.label)
        .map((topItem) => {
          const children = topItem.children
            ?.filter((subItem) => subItem.label)
            .map((subItem) => {
              return {
                name: subItem.label,
                onClick:
                  (isNord && !subItem.children) || (isNord && isGddsDesktop) || !isNord
                    ? navClick(subItem)
                    : undefined,
                url: subItem.url,
                withoutIcon: !subItem.children,
                items: subItem.children?.map((subSubItem) => ({
                  name: subSubItem.label,
                  onClick: navClick(subSubItem),
                  url: subSubItem.url,
                  withoutIcon: !subSubItem.children,
                })),
              };
            });

          let childItems =
            children?.length > 0
              ? {
                  ariaLabel: topItem.label,
                  title: topItem.label,
                  popupMenuItems: children,
                }
              : undefined;

          if (isNord && !isGddsDesktop) {
            childItems = children?.length > 0 ? children : undefined;
          }

          return {
            name: topItem.label,
            onClick:
              (isNord && !topItem.children) || (isNord && isGddsDesktop) || !isNord
                ? navClick(topItem)
                : undefined,
            url: topItem.url,
            popupMenu: childItems,
          };
        });

      return restructuredItems;
    }

    return [];
  }, [navItemsRaw, isGddsDesktop]);

  const initialActiveItem = useMemo(() => {
    let menuItemIndex = 0;
    let popupMenuItemIndex = 0;

    if (navItemsRaw && Array.isArray(navItemsRaw)) {
      for (const topItem of navItemsRaw) {
        popupMenuItemIndex = 0;
        let foundedSub = false;
        for (const subItem of topItem?.children || []) {
          let pathnameTmp = subItem.url;
          if (subItem?.url?.startsWith('http')) {
            pathnameTmp = subItem.url?.replace(new URL(subItem.url).origin, '');
          }

          if (pathnameTmp?.length > 1 && pathname.startsWith(pathnameTmp)) {
            foundedSub = true;
            break;
          }

          popupMenuItemIndex++;
        }

        if (foundedSub || pathname.startsWith(topItem.url)) {
          break;
        }
        menuItemIndex++;
      }
    }
    return {
      menuItemIndex,
      popupMenuItemIndex,
    };
  }, [pathname, navItemsRaw]);

  const transformnavItemsRaw = transformNavItemsRawFactory(
    navClick,
    translate('web20_menu_tooverview'),
  );

  const menuBarValue = useMemo(() => {
    return {
      items: isNord && !isGddsDesktop ? transformnavItemsRaw(navItemsRaw) : navItems,
      initialActiveItem,
      onChange: () => {},
      metaNavigationProps: metanaviData,
    };
  }, [isNord, isGddsDesktop, initialActiveItem, navItems, metanaviData]);

  // workaround for gdds navigation bug
  if (navItems?.length < 1) {
    return null;
  }

  const NaviComponent =
    !isNord || (isNord && isGddsDesktop) ? MainNavigationDesktop : Mobilenavigation;
  return (
    <MainNavigationWrapper>
      <GlobalMainNavigationStyles />
      <NaviComponent
        key={pathname}
        logo={{
          src: isNord
            ? customLogo?.logoRef || '/images/nordics_tmp.svg'
            : '/images/geberit_logo.svg',
          target: homepage?.url,
          alt: translate('web20_logo_tooltip'),
          language: isNord ? undefined : translate('web20_logo_claim_short'),
          onClick: () => {
            if (homepage?.url) {
              router.push(homepage.url);
            }
          },
        }}
        router={router}
        maxContentWidth={buildSize(gridSizes[themeToGridSize[theme]])}
        menuBar={menuBarValue}
        icons={[
          worldwide,
          !searchDisabled && {
            symbol:
              (isNord && !isGddsDesktop && !state.searchOpen) ||
              (isNord && isGddsDesktop) ||
              !isNord
                ? 'Search'
                : 'Close',
            onClick: toggleSearch,
          },
          isGroup &&
            headerPinEnabled && {
              symbol: 'Location',
              onClick: () => {
                if (locator?.url) {
                  router.push(locator?.url);
                }
              },
            },
        ]}
        homeLabel={homeLabel}
        backLabel={backLabel}
      />
      <SearchOverlay searchInputRef={searchInputRef} />
    </MainNavigationWrapper>
  );
}
