import { useSelector } from 'react-redux';

// components
import { LanguageSwitchDesktop } from './language-switch-desktop';

// utils
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';

export function LanguageSwitchDesktopWrapper() {
  const availableLanguages = useSelector(alternativePageLanguagesSelector);

  if (!availableLanguages || availableLanguages.length === 1) {
    return null;
  }

  return <LanguageSwitchDesktop />;
}
