import produce from 'immer';
import type { Reducer } from 'redux';

// types
import type { FormAction } from './actions';
import type { FormState } from './reducer.types';

const initialState = {};

/**
 * @param {Object} draft – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<FormState, FormAction> = produce((draft: FormState, action: FormAction) => {
  if ('language' in action && draft[action.language] === undefined) {
    draft[action.language] = {};
  }

  switch (action.type) {
    case 'PRE_CON_DATA_PENDING':
      draft.pending = true;
      break;

    case 'PRE_CON_DATA_FULFILLED':
      draft.pending = false;
      draft[action.language].preConDropdown = { products: action.products };
      break;

    case 'PRE_CON_DATA_REJECTED':
      draft.pending = false;
      draft[action.language].error = action.error;
      break;

    default:
  }
}, initialState);

export default reducer;
