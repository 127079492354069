'use client';

import Image from 'next/image';
import Link from 'next/link';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';

export function DummyHeader() {
  const logoTooltip = useTranslationByKey('web20_logo_tooltip');

  return (
    <div className="dummy-header-container">
      <div className="dummy-header-area">
        <div className="grid-container">
          <div className="logo">
            <Link href="#to-top" title={logoTooltip}>
              <Image src="/images/geberit_logo.svg" alt="logo" width={150} height={0} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
