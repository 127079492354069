// components
import { Profile } from './profile';

// utils
import { useMetaNavigationInfo } from '../../../../utils/use-metanav';

type ProfileProps = {
  showOnlyIcons: boolean;
  isIcon: boolean;
};

export function ProfileWrapper({ showOnlyIcons, isIcon }: Readonly<ProfileProps>) {
  const { ciamEnabled } = useMetaNavigationInfo();

  return ciamEnabled && <Profile showOnlyIcons={showOnlyIcons} isIcon={isIcon} />;
}
