import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// utils
import {
  navigationMainCampusItemsSelector,
  navigationMainItemsSelector,
} from 'utils/selectors/navigationSelectors';
import useIsCampus from 'features/header/utils/use-is-campus';
import { useIsMobile } from 'components/App/SizeProvider';

export function useNavigation() {
  const isCampus = useIsCampus();
  const navigation = useSelector(navigationMainItemsSelector);
  const campusNavigationItems = useSelector(navigationMainCampusItemsSelector);
  const isMobile = useIsMobile();
  let campusNavigation = campusNavigationItems;

  return useMemo(() => {
    let counter = 0;
    let items = navigation ?? [];
    while (counter < 2) {
      items = items.flatMap((e) => e.children ?? []) ?? [];
      counter++;
    }
    items.forEach((e) => {
      e.children = undefined;
    });

    if (isMobile) {
      campusNavigation = [
        {
          children:
            campusNavigationItems?.map((navigationItem, index) => {
              delete navigationItem.children;

              return { ...navigationItem, originalIndex: index };
            }) ?? [],
          label: 'Geberit Campus',
          url: '',
        },
      ];
    }

    return isCampus ? campusNavigation : (navigation ?? []);
  }, [campusNavigationItems, isCampus, navigation, isMobile]);
}
