import key from 'weak-key';

// Compoenents
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { Dropdown } from 'components/Dropdown/Dropdown';

// Styles
import styles from './language-selector.module.scss';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useRef } from 'react';

interface LanguageSelectorDesktopProps {
  sortedLanguages: Array<{
    lang: string;
    labelFull: string;
    url: string;
    disabled?: boolean;
  }>;
  language: string;
  clickHandler: (lang: string, url: string) => void;
  isPreview: boolean;
  isCampus?: boolean;
}

export default function LanguageSelectorDesktop({
  sortedLanguages,
  language,
  clickHandler,
  isPreview,
  isCampus = false,
}: Readonly<LanguageSelectorDesktopProps>) {
  const dropdownRef = useRef<any>(null);
  return (
    <nav>
      <Dropdown
        placeholder={language?.substring(0, 2)}
        label={language?.substring(0, 2)}
        className={classNameBuilder(styles.languageSelector, isCampus && 'campus')}
        ref={dropdownRef}
      >
        <ul>
          {sortedLanguages.map((item) => {
            const handleClick = () => {
              clickHandler(item.lang, item.url);
              dropdownRef?.current?.setDropdownActive(false);
            };

            const isActive = language === item.lang;
            const disabledClass = item.disabled && !isPreview ? styles.disabledItem : '';
            const activeClass = isActive ? styles.active : '';

            return isActive || (item.disabled && !isPreview) ? (
              <li
                className={`${styles.languageItem} ${activeClass} ${disabledClass}`}
                key={key(item)}
              >
                {activeClass && <Icon symbol="check" />}
                <span className={styles.control}>{item.labelFull}</span>
              </li>
            ) : (
              <li className={styles.languageItem} key={key(item)}>
                <Button
                  className={styles.control}
                  type={BUTTON_TYPES.PLAIN}
                  onClick={handleClick}
                  noTracking
                >
                  {item.labelFull}
                </Button>
              </li>
            );
          })}
        </ul>
      </Dropdown>
    </nav>
  );
}
