import styled, { css } from 'styled-components';

export const MetaNavContainer = styled.div`
  ${({ theme }) => {
    return css`
      width: 100%;
      padding: 1.5rem 0;
      background-color: ${theme.colors.cl05};
      margin-top: auto;
      @media ${theme.device.large} {
        display: none;
      }
    `;
  }}
`;

export const ButtonContainer = styled.div(({ theme }) => {
  return css`
    @media (min-width: 375px) {
      .basket {
        display: none;
      }
    }
    @media ${theme.device.medium} {
      > button {
        display: none;
      }
    }
  `;
});

export const MetaNavLinksContainer = styled.ul`
  margin: 0;
  padding: 0;
`;
